import React, { useEffect, useState } from 'react'
import { JarvisPortalTemplate } from '@jarvis/react-portal-additions/dist/index'
import * as ld from 'launchdarkly-js-client-sdk'
import {
  DEVICE_CONTROL_CENTER_READ_PERM,
  DEVICE_FLEET_OPS_READ_PERM,
  MANAGE_PRINT_CENTRAL_READ_PERM,
  MPS_SUPPORTING_PORTAL_READPERM,
  MS_EMAIL_SUPPORT_READPERM,
  MPS_PRINTER_ONBOARDING_TOOL_READ_PERM,
  FLEET_PROXY_INSTALLER_READ_PERM,
  STANDARD_ICON_SIZE
} from 'src/utils/constants'
import {
  NotificationCenter,
  NotificationItemInterface,
  Button,
  IconHelp,
  IconExternalLink,
  IconEnvelope,
  IconDownload
} from '@veneer/core'
import ldConfig from 'src/mocks/ld-config.json'
import { useShellRootContext } from 'src/contexts/ShellRoot'
import { getCliendId, setFlags } from 'src/utils/commonMethods'
import { EcpSupportContainer } from './styles'
import { EcpSupportProps } from './type'

enum UserContextEnum {
  organization = 'organization',
  customer = 'customer',
  orgless = 'orgless'
}

type AccessControlOptionsType = {
  userContext: UserContextEnum
}

const EcpSupport = (props: EcpSupportProps) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const accessControl =
    interfaces?.v1?.accessControl || props?.mockAccessControl
  const authStack =
    interfaces?.v1?.app?.getAuthStack?.() || props?.mockAuthStack

  const showAllLinks = props?.showAllLinks || undefined
  const callSetFlags = props?.callSetFlags || undefined

  const [showMPS, setShowMPS] = useState<boolean>(false)
  const [showMSsupport, setShowMSsupport] = useState<boolean>(false)
  const [showFleetOps, setShowFleetOps] = useState<boolean>(false)
  const [showDCC, setShowDCC] = useState<boolean>(false)
  const [showMPC, setShowMPC] = useState<boolean>(false)
  const [showFPI, setShowFPI] = useState<boolean>(false)
  const [showMPS_PrinterOnboardingTool, setShowMPS_PrinterOnboardingTool] =
    useState<boolean>(false)

  const [readScopeForMPS, setReadScopeForMPS] = useState<boolean>(
    showAllLinks === 1 ? true : false
  )
  const [readScopeForMSsupport, setReadScopeForMSsupport] = useState<boolean>(
    showAllLinks === 1 ? true : false
  )
  const [readScopeForDCC, setReadScopeForDCC] = useState<boolean>(
    showAllLinks === 1 ? true : false
  )
  const [readScopeForFleetOps, setReadScopeForFleetOps] = useState<boolean>(
    showAllLinks === 1 ? true : false
  )
  const [readScopeForMPC, setReadScopeForMPC] = useState<boolean>(
    showAllLinks === 1 ? true : false
  )
  const [readScopeForFPI, setReadScopeForFPI] = useState<boolean>(
    showAllLinks === 1 ? true : false
  )
  const [readScopeForMPSPOT, setReadScopeForMPSPOT] = useState<boolean>(
    showAllLinks === 1 ? true : false
  )

  const options: AccessControlOptionsType = {
    userContext: UserContextEnum.organization
  }

  useEffect(() => {
    accessControl
      ?.checkScopes(MPS_SUPPORTING_PORTAL_READPERM, options)
      .then((result) => setReadScopeForMPS(result))
    accessControl
      ?.checkScopes(MS_EMAIL_SUPPORT_READPERM, options)
      .then((result) => setReadScopeForMSsupport(result))
    accessControl
      ?.checkScopes(DEVICE_CONTROL_CENTER_READ_PERM, options)
      .then((result) => setReadScopeForDCC(result))
    accessControl
      ?.checkScopes(DEVICE_FLEET_OPS_READ_PERM, options)
      .then((result) => setReadScopeForFleetOps(result))
    accessControl
      ?.checkScopes(MANAGE_PRINT_CENTRAL_READ_PERM, options)
      .then((result) => setReadScopeForMPC(result))
    accessControl
      ?.checkScopes(FLEET_PROXY_INSTALLER_READ_PERM, options)
      .then((result) => setReadScopeForFPI(result))
    accessControl
      ?.checkScopes(MPS_PRINTER_ONBOARDING_TOOL_READ_PERM, options)
      .then((result) => setReadScopeForMPSPOT(result))
  })

  useEffect(() => {
    const launchDarklyClientId = getCliendId(authStack, ldConfig)

    const client: ld.LDClient = ld.initialize(
      launchDarklyClientId,
      ldConfig.launchDarkly.user
    )

    const options = {
      client,
      showAllLinks,
      setShowMPS,
      setShowMSsupport,
      setShowFleetOps,
      setShowDCC,
      setShowMPC,
      setShowFPI,
      setShowMPS_PrinterOnboardingTool
    }

    if (callSetFlags) {
      setFlags(options)
    } else {
      // do nothing
    }

    client.on('ready', () => {
      setFlags(options)
    })

    client.on('change', () => {
      setFlags(options)
    })
  }, [
    showAllLinks,
    authStack,
    showMPS,
    showMSsupport,
    showFleetOps,
    showDCC,
    showMPC,
    showFPI,
    showMPS_PrinterOnboardingTool,
    callSetFlags
  ])

  /* istanbul ignore next */
  const content = (
    showMPS: boolean,
    showMSsupport: boolean,
    showFleetOps: boolean,
    showDCC: boolean,
    showMPC: boolean,
    showFPI: boolean,
    showMPS_PrinterOnboardingTool: boolean
  ) => {
    const triggerButton = () =>
      document.getElementById('support-center-button').click()

    const mps = {
      content: `${t(
        'ecp-support.notification0Content',
        'Customer service center for HP MPS customers, partners, and HP personnel'
      )}`,
      id: 'notification-0',
      icon: (
        <div>
          <IconExternalLink size={STANDARD_ICON_SIZE} />
        </div>
      ),
      onClickItem: () => {
        if (authStack === 3) {
          return (
            window.open(
              'https://print.services.api.hp.com/paladin-auth/login/hpid?apikey=H2fxTBaGcgOG3pxmquxMihNaCsAn91d2',
              '_blank',
              'noopener'
            ),
            triggerButton()
          )
        } else {
          return (
            window.open(
              'https://hpcorp-ob-test.default.api.hp.com/paladin-auth/login/hpid?apikey=zRBBiXLJ6Em97SmdLjVaIlMVNSROe5CK',
              '_blank',
              'noopener'
            ),
            triggerButton()
          )
        }
      },
      read: true,
      title: `${t(
        'ecp-support.notification0Title',
        'Managed Print Service Supporting Portal'
      )}`
    }

    const msSupport = {
      content: `${t(
        'ecp-support.notification1Title',
        'Contact HP Support to get help'
      )}`,
      id: 'notification-1',

      icon: (
        <div>
          <IconEnvelope size={STANDARD_ICON_SIZE} />
        </div>
      ),
      read: true,
      title: `${t('ecp-support.notification1Content', 'mssupport@hp.com')}`,
      onClickItem: () => (
        (window.location.href = 'mailto:mssupport@hp.com'), triggerButton()
      )
    }

    const fleetOps = {
      content: `${t(
        'ecp-support.notification2Content',
        'Fleet device management portal for HP personnel that manages remote resolution of connectivity and device issues.'
      )}`,
      id: 'notification-2',
      icon: (
        <div>
          <IconExternalLink size={STANDARD_ICON_SIZE} />
        </div>
      ),
      onClickItem: () =>
        authStack === 3
          ? (window.open(
              'https://print.services.api.hp.com/paladin-auth/login/hpid?apikey=t9DZEaJG37mu5aAShIt64DPGpZzX41xE',
              '_blank',
              'noopener'
            ),
            triggerButton())
          : (window.open(
              'https://hpcorp-ob-test.default.api.hp.com/paladin-auth/login/hpid?apikey=TahUBtO5woQOxrw49PJPkHgQJw4tfPlm',
              '_blank',
              'noopener'
            ),
            triggerButton()),
      read: true,
      title: `${t(
        'ecp-support.notification2Title',
        'HP Device Connect Fleet Operations'
      )}`
    }

    const dcc = {
      content: `${t(
        'ecp-support.notification3Content',
        'Fleet device management portal for customers that provides real-time fleet status, ability to monitor usage, request supplies or service and generate reports.'
      )}`,
      id: 'notification-3',
      icon: (
        <div>
          <IconExternalLink size={STANDARD_ICON_SIZE} />
        </div>
      ),
      onClickItem: () =>
        authStack === 3
          ? (window.open(
              'https://print.services.api.hp.com/paladin-auth/login/hpid?apikey=9SqHHrxBgwPP1vJJsJbY4DlJZwkt0JHx',
              '_blank',
              'noopener'
            ),
            triggerButton())
          : (window.open(
              'https://hpcorp-ob-test.default.api.hp.com/paladin-auth/login/hpid?apikey=JKaI5y7txY1yoBVq1Rn0trONmwMU4bQy',
              '_blank',
              'noopener'
            ),
            triggerButton()),
      read: true,
      title: `${t('ecp-support.notification3Title', 'Device Control Center')}`
    }

    const mpc = {
      content: `${t(
        'ecp-support.notification4Content',
        'Managed Print Central (MPC) is the partner portal for HP contractual business which includes MPC and Managed Print Flex Portal'
      )}`,
      id: 'notification-4',
      icon: (
        <div>
          <IconExternalLink size={STANDARD_ICON_SIZE} />
        </div>
      ),
      onClickItem: () =>
        authStack === 3
          ? (window.open(
              'https://print.services.api.hp.com/paladin-auth/login/hpid?apikey=5Yx5SaylCnUfA8G6OHZ1kQyKq39x7DwP',
              '_blank',
              'noopener'
            ),
            triggerButton())
          : (window.open(
              'https://hpcorp-ob-test.default.api.hp.com/paladin-auth/login/hpid?apikey=AxxhPccNoXGgXqu1MGakZ7xzUSZR4Y4v',
              '_blank',
              'noopener'
            ),
            triggerButton()),
      read: true,
      title: `${t('ecp-support.notification4Title', 'Managed Print Central')}`
    }
    const fleetProxyInstaller = {
      content: `${t(
        'ecp-support.notification6Content',
        'Fleet Proxy installer allows you to add proxy connected devices at once.'
      )}`,
      id: 'notification-5',
      icon: (
        <div>
          <IconDownload size={STANDARD_ICON_SIZE} />
        </div>
      ),
      onClickItem: () =>
        authStack === 3
          ? (window.open(
              'https://print.services.api.hp.com/paladin-auth/login/hpid?apikey=9SqHHrxBgwPP1vJJsJbY4DlJZwkt0JHx',
              '_blank',
              'noopener'
            ),
            triggerButton())
          : (window.open(
              'https://hpcorp-ob-test.default.api.hp.com/paladin-auth/login/hpid?apikey=JKaI5y7txY1yoBVq1Rn0trONmwMU4bQy',
              '_blank',
              'noopener'
            ),
            triggerButton()),
      read: true,
      title: `${t('ecp-support.notification6', 'HP Fleet Proxy')}`
    }
    const printerOnboardingTool = {
      title: `${t(
        'ecp-support.notification5Title',
        'MPS Printer Onboarding Tool'
      )}`,
      content: `${t(
        'ecp-support.notification5Content',
        'MPS Printer Onboarding tool allows you to add many devices at once'
      )}`,
      read: true,
      id: 'notification-6',
      icon: (
        <div>
          <IconDownload size={STANDARD_ICON_SIZE} />
        </div>
      ),
      onClickItem: () =>
        authStack === 3
          ? (window.location.assign(
              'https://d2fma59bc9qsz9.cloudfront.net/download/hp-mps-printer-onboarding-tool-latest-win-x64.exe '
            ),
            triggerButton())
          : (window.location.assign(
              'https://d1azgct71qxt3b.cloudfront.net/dev/download/hp-mps-printer-onboarding-tool-latest-win-x64.exe'
            ),
            triggerButton())
    }

    const linksArray: NotificationItemInterface[] = [
      {
        appearance: 'section',
        id: 'notification-section-0',
        read: true,
        title: `${t('ecp-support.supportSubTitle', 'Support')}`
      },
      mps,
      msSupport,
      {
        appearance: 'section',
        id: 'notification-section-1',
        read: true,
        title: `${t('ecp-support.resourcesSubTitle', 'Resources')}`
      },
      fleetOps,
      dcc,
      fleetProxyInstaller,
      mpc,
      printerOnboardingTool
    ]

    const itemsMakerFunction = () => {
      let result: NotificationItemInterface[] = linksArray

      // conditions for filtering notifications
      !showMPS || !readScopeForMPS
        ? (result = result.filter((i) => i.id !== 'notification-0'))
        : null
      !showMSsupport || !readScopeForMSsupport
        ? (result = result.filter((i) => i.id !== 'notification-1'))
        : null
      !showFleetOps || !readScopeForFleetOps
        ? (result = result.filter((i) => i.id !== 'notification-2'))
        : null
      !showDCC || !readScopeForDCC
        ? (result = result.filter((i) => i.id !== 'notification-3'))
        : null
      !showMPC || !readScopeForMPC
        ? (result = result.filter((i) => i.id !== 'notification-4'))
        : null
      !showFPI || !readScopeForFPI
        ? (result = result.filter((i) => i.id !== 'notification-5'))
        : null
      !showMPS_PrinterOnboardingTool || !readScopeForMPSPOT
        ? (result = result.filter((i) => i.id !== 'notification-6'))
        : null

      //  condition for filtering 'SUPPORT' section
      result.some((i) => i.id === 'notification-0') ||
      result.some((i) => i.id === 'notification-1')
        ? null
        : (result = result.filter((i) => i.id !== 'notification-section-0'))

      // condition for filtering 'RESOURCES' section
      result.some((i) => i.id === 'notification-2') ||
      result.some((i) => i.id === 'notification-3') ||
      result.some((i) => i.id === 'notification-4') ||
      result.some((i) => i.id === 'notification-5') ||
      result.some((i) => i.id === 'notification-6')
        ? null
        : (result = result.filter((i) => i.id !== 'notification-section-1'))

      return result
    }

    return (
      <NotificationCenter
        id="support-container"
        placement="bottom-end"
        items={itemsMakerFunction()}
        i18n={{
          title: t('ecp-support.pageHeader', 'Support & Resources'),
          actionButton: 'Action button',
          noNotifications: 'There are no notifications.'
        }}
      >
        <Button
          appearance="tertiary"
          aria-label="support_center"
          data-testid="support-center-button"
          id="support-center-button"
          leadingIcon={<IconHelp />}
        />
      </NotificationCenter>
    )
  }

  return (
    <EcpSupportContainer data-testid="ecp-support-container">
      <JarvisPortalTemplate
        direction="ltr"
        fullWidth
        data-testid="jarvis-portal-template"
        mainContent={content(
          showMPS,
          showMSsupport,
          showFleetOps,
          showDCC,
          showMPC,
          showFPI,
          showMPS_PrinterOnboardingTool
        )}
      />
    </EcpSupportContainer>
  )
}

export default EcpSupport
