export const LocaleStrings = {
  pageHeader: 'pageHeader',
  supportSectionHeading: 'supportSectionHeading',
  resourceSectionHeading: 'resourceSectionHeading',
  notification0Title: 'notification0Title',
  notification0Content: 'notification0Content',
  notification1Title: 'notification1Title',
  notification1Content: 'notification1Content',
  notification2Title: 'notification2Title',
  notification2Content: 'notification2Content',
  notification3Title: 'notification3Title',
  notification3Content: 'notification3Content',
  notification4Title: 'notification4Title',
  notification4Content: 'notification4Content',
  notification5Title: 'notification5Title',
  notification5Content: 'notification5Content',
  notification6Title: 'notification6Title',
  notification6Content: 'notification6Content'
}

export const MPS_SUPPORTING_PORTAL_READPERM = [
  { scope: 'ws-hp.com/ecp/mpsSupportingPortal.READ' }
]

export const MS_EMAIL_SUPPORT_READPERM = [
  { scope: 'ws-hp.com/ecp/emailSupport.READ' }
]

export const DEVICE_CONTROL_CENTER_READ_PERM = [
  { scope: 'ws-hp.com/ecp/deviceControlCenter.READ' }
]

export const DEVICE_FLEET_OPS_READ_PERM = [
  { scope: 'ws-hp.com/ecp/fleetOps.READ' }
]

export const MANAGE_PRINT_CENTRAL_READ_PERM = [
  { scope: 'ws-hp.com/ecp/managedPrintCentral.READ' }
]

export const MPS_PRINTER_ONBOARDING_TOOL_READ_PERM = [
  { scope: 'ws-hp.com/ecp/mpsPrinterOnboardingTool.READ' }
]
export const FLEET_PROXY_INSTALLER_READ_PERM = [
  { scope: 'ws-hp.com/ecp/fleetProxyInstaller.READ' }
]

export const STANDARD_ICON_SIZE = 24
