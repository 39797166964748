import styled from 'styled-components'
import primitives from '@veneer/primitives'

const defaultWidgetSize = 48

export const EcpSupportContainer = styled.div`
  text-align: center;
  > div,
  > div > div,
  > div > div > div,
  > div > div > div > button {
    width: ${defaultWidgetSize}px;
    height: ${defaultWidgetSize}px;
    padding: 0 !important;

    border-radius: ${primitives.layout.size3}px;
  }
  #support-center-button {
    margin-bottom: 0 !important;
  }
  #support-container > div {
    width: 380px;
    .subtitle-regular {
      font-family: ${primitives.typography.family0} !important;
      font-size: ${primitives.typography.size4};
      line-height: ${primitives.typography.lineHeight4};
      padding: ${primitives.layout.size0}px;
    }
    ul {
      padding-left: ${primitives.layout.size0}px;
      max-height: none !important;
      margin: ${primitives.layout.size0}px;
    }
  }
  li[id='notification-0'],
  li[id='notification-1'],
  li[id='notification-2'],
  li[id='notification-3'],
  li[id='notification-4'],
  li[id='notification-5'],
  li[id='notification-6'] {
    text-align: left;
    padding-left: ${primitives.layout.size1}px;

    > div:first-child + div {
      font-weight: 400;
      span {
        font-weight: 400;
      }
    }

    > div:last-child {
      display: none;
    }
  }
`
export const MainContent = styled.div`
  padding-top: ${primitives.layout.size5}px !important;
  font-family: ${primitives.typography.family0} !important;
  font-weight: 600 !important;
  font-size: ${primitives.typography.size2} !important;
  line-height: ${primitives.typography.lineHeight2} !important;
`
