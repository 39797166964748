export const getCliendId = (authStack, ldConfig) => {
  if (authStack === 0) {
    return ldConfig.launchDarkly.clientId.dev
  } else if (authStack === 1) {
    return ldConfig.launchDarkly.clientId.pie
  } else if (authStack === 2) {
    return ldConfig.launchDarkly.clientId.stage
  } else if (authStack === 3) {
    return ldConfig.launchDarkly.clientId.prod
  } else {
    return ldConfig.launchDarkly.clientId.pie
  }
}

export const setFlags = ({
  client,
  showAllLinks,
  setShowMPS,
  setShowMSsupport,
  setShowFleetOps,
  setShowDCC,
  setShowMPC,
  setShowFPI,
  setShowMPS_PrinterOnboardingTool
}) => {
  const mpsFlagValue = client.variation('ecp-support-mps')
  const msSupportFlagValue = client.variation('ecp-support-mssupport-email')
  const fleetOpstFlagValue = client.variation('ecp-support-fleet-ops')
  const dccFlagValue = client.variation('ecp-support-dcc')
  const mpcFlagValue = client.variation('ecp-support-mpc')
  const fpiFlagValue = client.variation('ecp-fleet-proxy')
  const mpsPOT_FlagValue = client.variation('ecp-mps-printer-onboarding-tool')
  setShowMPS(showAllLinks === 1 ? true : mpsFlagValue)
  setShowMSsupport(showAllLinks === 1 ? true : msSupportFlagValue)
  setShowFleetOps(showAllLinks === 1 ? true : fleetOpstFlagValue)
  setShowDCC(showAllLinks === 1 ? true : dccFlagValue)
  setShowMPC(showAllLinks === 1 ? true : mpcFlagValue)
  setShowFPI(showAllLinks === 1 ? true : fpiFlagValue)
  setShowMPS_PrinterOnboardingTool(showAllLinks === 1 ? true : mpsPOT_FlagValue)
}
